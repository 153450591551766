<template>
  <router-view/>
</template>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
}

.content{
  width: 1190px;
  margin: auto;
  display: flex;
}

ul{
  list-style: none;
}

button{
  border: none;
  outline: none;
}
</style>
